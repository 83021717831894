<template>
  <v-main>
    <ParcelDetailById />
    <SelectAccount v-if="!dialogs.selectAccount" />
    <v-dialog v-model="dialogs.createParcel" max-width="800">
      <v-card class="mx-auto">
        <v-card-title
          >Add New Parcel <v-spacer></v-spacer>
          <v-btn icon @click="dialogs.createParcel = !dialogs.createParcel"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-card-title
        >

        <v-card-text>
          <parcel-create
            @created="parcelCreated"
            @cancel="dialogs.createParcel = false"
          ></parcel-create>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.hoorayDialog" transition="dialog-bottom-transition" max-width="600">
      <peeps-hooray>
        <template v-slot:baseTitle>
          <span class="peeps--font">Congratulations!</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogs.hoorayDialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </template>
        <div style="font-size: 0.95rem">
          <p>
            You just added your parcel to your Peeps Portal. If you have more than one parcel, you
            can add that one as well.
          </p>
          <p
            v-if="
              featuresEnabled(featuresComputed).length || featuresEnabledParcel(latestParcel).length
            "
          >
            And now just click one of the buttons below to get started on updating information on
            your property. Start anywhere you like - all the features have been designed to be easy
            to use, and will be accessible to you anytime, anywhere, and on any device you want to
            use such as your desktop, laptop, tablet and/or smartphone.
          </p>
        </div>
        <div class="d-flex justify-center" v-if="!latestParcel">
          <v-skeleton-loader class="ml-3" v-for="i in 3" :key="i" type="button"></v-skeleton-loader>
        </div>
        <div v-else class="text-center">
          <span v-for="(feature, index) in featuresList" :key="index">
            <feature-button
              class="mr-2 mt-2"
              v-if="
                isFeatureAvailable(feature.feature) ||
                (latestParcel && latestParcel.features && latestParcel.features[feature.feature])
              "
              :value="feature.feature"
              :to="featureLink(feature)"
              large
            ></feature-button>
          </span>
        </div>
      </peeps-hooray>
    </v-dialog>
    <div class="parcel-view-wrapper">
      <v-container fluid class="parcel-heading">
        <div class="d-flex justify-space-between">
          <div>
            <div class="d-flex flex-wrap">
              <div class="d-none d-sm-inline">
                <h1 class="heading">Parcels</h1>
              </div>
              <div
                v-if="parcels.length && permissions.read"
                class="ml-1"
                style="margin-top: 13px; margin-bottom: 5px"
              >
                <v-btn-toggle color="primary">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" small value="card" to="/parcels" exact>
                        <v-icon>mdi-select-group</v-icon>
                      </v-btn>
                    </template>
                    <span>Card View</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="parcels.length >= 3">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" small value="list" to="/parcels/list">
                        <v-icon>mdi-table-of-contents</v-icon>
                      </v-btn>
                    </template>
                    <span>List View</span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="showMapView">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" small value="map" to="/parcels/map">
                        <v-icon>mdi-map</v-icon>
                      </v-btn>
                    </template>
                    <span>Map View</span>
                  </v-tooltip>
                </v-btn-toggle>
                <v-menu
                  :close-on-content-click="false"
                  v-model="dialogs.mapSetting"
                  transition="scale-transition"
                  max-height="90vh"
                >
                  <template v-slot:activator="{ on }">
                    <v-badge
                      overlap
                      class="mr-4 ml-1"
                      v-show="loggedInAs.type === 'organization'"
                      :content="parcelsComputed.length"
                      :value="parcelsComputed.length"
                    >
                      <v-btn v-on="on" class="map-setting" icon>
                        <v-icon>mdi-filter-outline</v-icon>
                      </v-btn>
                    </v-badge>
                  </template>
                  <map-settings
                    show-stat
                    :parcels="parcelsComputed"
                    @close="dialogs.mapSetting = false"
                    :show="parcelFilter"
                  ></map-settings>
                </v-menu>
                <v-badge :value="searchCount && !searchDialog" :content="searchCount" overlap>
                  <v-btn
                    class="search-btn"
                    :class="{ 'v-btn--active': searchDialog || searchCount }"
                    icon
                    @click="toggleSearchDialog()"
                    color="primary"
                    ><v-icon> mdi-magnify</v-icon>
                  </v-btn>
                </v-badge>
              </div>
            </div>
          </div>
          <div class="mt-3" v-if="permissions.create || $store.getters.isAdmin">
            <v-btn
              @click="exportCsv()"
              v-show="viewMode === 'list'"
              color="primary"
              small
              class="mr-1 text-capitalize"
            >
              <span class="d-none d-sm-block"> Export </span>
              <v-icon color="white">$downloadCsv</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              @click="dialogs.createParcel = true"
              small
              class="text-capitalize"
            >
              <span class="d-none d-sm-block"> New Parcel</span>
              <v-icon>mdi-plus</v-icon></v-btn
            >
          </div>
        </div>
        <ParcelSearchDialog v-show="$store.state.parcel.searchDialog" :count.sync="searchCount" />
      </v-container>
      <div class="text-center mt-9" v-if="!parcels.length && !loading && permissions.read">
        <v-container fluid>
          <v-alert color="info" class="peeps--font" text>
            Please use the NEW PARCEL button to Add a parcel to your
            {{ loggedInAs.type === 'organization' ? 'organization' : 'Peeps Portal' }}.</v-alert
          >
        </v-container>
      </div>
      <v-container v-if="!permissions.read">
        <v-alert type="error" max-width="600" class="mx-auto"
          >You do not have permissions to access parcels of this organization</v-alert
        >
      </v-container>
      <router-view class="parcel-router-view"></router-view>
      <!-- <div class="child"></div> -->
    </div>
  </v-main>
</template>

<script>
import parcel from '@/mixins/parcel';
import featureMixin from '@/mixins/feature';
import searchParcelMixin from '@/mixins/searchParcel';
import parcelsComputedMixin from '../../mixins/parcelsComputed';

import ParcelCreate from '@/components/Parcel/CreateParcel';
import PeepsHooray from '@/components/PeepsHooray';
import MapSettings from '../../components/Parcel/MapSettings';
import { mapGetters, mapState } from 'vuex';

import config from '../../config';
import Vue from 'vue';

import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import { exportParcelsToCsv } from '../../utils/parcel';
import { sort } from '../../utils';
import { teamFilter, landFilter, getStreetAddress } from '../../utils/parcel';
import { parseTimestamp } from '../../utils/firebase';

mapboxgl.accessToken = config.mapboxToken;
Vue.prototype.$mapbox = mapboxgl;

export default {
  metaInfo: {
    title: 'Parcel'
  },
  mixins: [parcel, featureMixin, searchParcelMixin, parcelsComputedMixin],
  components: {
    ParcelCreate,
    PeepsHooray,
    MapSettings,
    FeatureButton: () => import('@/components/Core/Features/FeatureButton.vue'),
    ParcelSearchDialog: () => import('@/components/Parcel/SearchDialog'),
    SelectAccount: () => import('../../components/SelectAccount.vue'),
    ParcelDetailById: () => import('../../components/Parcel/DetailDialog.vue')
    // ListView,
    // MapView,
  },
  data: () => ({
    dialogs: {
      createParcel: false,
      hoorayDialog: false,
      mapSettings: false,
      selectAccount: true
    },
    currentParcel: null,
    geoUpdated: false,
    searchCount: 0,
    click: 0,
    timeout: null
  }),

  beforeCreate() {
    this.$store.commit('resetParcelDetail');
  },

  created() {
    this.fetchParcels();
  },

  async mounted() {
    this.storeParcelTypes();
    this.checkParcelId();
    setTimeout(() => {
      this.dialogs.selectAccount = !!localStorage.getItem('selectAccount');
    }, 1000);
    this.$bus.$on('clickParcel', this.showParcelDetail);
    this.checkHighlight();
  },

  beforeDestroy() {
    this.removeSubs();
    this.$bus.$off('clickParcel', this.showParcelDetail);
  },

  computed: {
    mapView() {
      let mode = this.$route.path.split('/').pop();
      if (mode === 'parcels') mode = 'card';
      return mode;
    },
    parcelFilter() {
      const res = ['team', 'landPreserve', 'ownership', 'monitoringDue'];
      const mode = this.mapView;
      if (mode === 'map') {
        res.push('showPreserve');
      }
      return res;
    },
    loading: {
      get() {
        return this.$store.getters.parcelLoading;
      },
      set(val) {
        this.$store.commit('parcelLoading', val);
      }
    },
    showMapView() {
      const parcelsWithMap = this.parcels.filter(e => {
        return e.center || e.geojson;
      });
      return parcelsWithMap.length > 1;
    },
    viewMode() {
      return this.$route.path.split('/').pop();
    },
    mapConfig() {
      return this.$store.state.parcel.mapConfig;
    },
    ...mapGetters(['parcels', 'latestParcel', 'featuresList']),
    ...mapState(['permissions', 'loggedInAs'])
  },
  methods: {
    toggleSearchDialog() {
      if (this.$store.state.parcel.searchDialog) {
        this.click += 1;
        if (this.timeout) {
          clearTimeout(this.timeout);
          this.timeout = null;
        }
        this.timeout = setTimeout(() => {
          if (this.click > 1) {
            this.$store.commit('setSearchQuery', '');
          }
          this.$store.commit('setSearchDialog', false);
          this.click = 0;
        }, 500);
      } else {
        this.click = 0;
        this.$store.commit('setSearchDialog', true);
      }
    },
    checkHighlight() {
      const id = this.$route.query.highlight;
      if (id) {
        this.showParcelDetail({ id });
      }
    },
    setParcelDetail(key, value) {
      this.$store.commit('setParcelDetail', { key, value });
    },
    showParcelDetail(parcel) {
      if (this.viewMode === 'map') {
        return;
      }
      this.setParcelDetail('id', parcel.id);
      this.setParcelDetail('dialog', true);
    },
    exportCsv() {
      let parcels = teamFilter(this.parcels, this.mapConfig.team);
      parcels = landFilter(parcels, this.mapConfig.land);

      parcels.map(p => {
        const lastUpdated = (p.lastUpdated =
          p?.recent_activity?.createdAt || p?.recent_activity?.timestamp);
        if (lastUpdated) {
          try {
            p.lastUpdated = parseTimestamp(lastUpdated).getTime();
          } catch {
            p.lastUpdated = 0;
          }
        } else {
          p.lastUpdated = 0;
        }

        p.address = getStreetAddress(p);

        return p;
      });
      exportParcelsToCsv(parcels);
    },
    featuresEnabledParcel(parcel) {
      if (parcel && parcel.features) {
        return this.featuresEnabled(parcel.features);
      }
      return false;
    },
    removeSubs() {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
    },
    processSnap(snap) {
      let data = [];
      snap.forEach(item => {
        data.push({ ...item.data(), id: item.id });
      });

      data = sort({ items: data, field: 'number' });

      this.$store.commit('setParcels', [...data]);
      this.$bus.$emit('parcelLoad');
      if (!this.geoUpdated) {
        this.updateGeoData();
        this.geoUpdated = true;
      }
      this.loading = false;
    },
    fetchParcels() {
      this.removeSubs();
      const uid = this.$store.state.loggedInAs.id || localStorage.getItem('uid');
      if (uid) {
        this.loading = true;
        const q = this.buildParcelQuery(uid);
        this.unsubscribe = q.onSnapshot(
          snap => {
            this.processSnap(snap);
          },
          error => {
            this.loading = false;
            console.error(`Error fetching parcels: ${error}`);
            const { captureException } = require('@sentry/vue');
            captureException(error);
          }
        );
      }
    },
    parcelCreated() {
      this.dialogs.createParcel = false;
      if (this.$route.query.parcelId) {
        this.$router.push('/parcels');
      }
      if (this.$store.state.parcel.parcels.length > 1) {
        return;
      }
      setTimeout(() => {
        this.dialogs.hoorayDialog = true;
      }, 500);
    },
    featureLink(feature) {
      if (!this.latestParcel) {
        return '';
      } else {
        return `/parcels/${this.latestParcel.id}/${feature.url}`;
      }
    },
    checkParcelId() {
      let parcelId = this.$route.query.parcelId;
      if (!parcelId) {
        return;
      }
      this.dialogs.createParcel = true;
    },
    getParcelGeoData(data) {
      if (data.geojson) {
        return;
      }
      this.updateParcelGeoData(data.id);
    },
    updateGeoData() {
      this.parcels.map(parcel => {
        this.getParcelGeoData(parcel);
      });
    }
  },
  watch: {
    '$store.state.loggedInAs.id': {
      handler() {
        this.fetchParcels();
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.map-setting {
  margin-top: -10px !important;
}
.search-btn {
  margin-top: -10px !important;
}

.parcel-heading {
  z-index: 3;
  position: sticky;
  top: 64px;
  @media (max-width: 960px) {
    top: 56px;
  }
  background-color: #ffffff;
}
.parcel-view-wrapper {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  height: 100%;
}
.parcel-router-view {
  position: relative;
  height: 100%;
}
</style>
