<template>
  <base-parcel-form
    @submit="createParcel"
    @cancel="$emit('cancel')"
    @change:parcel="checkParcelExists"
    :data="parcelData"
    :disableCreate="disableCreateParcel"
  >
    <template v-slot:button> Add <v-icon>mdi-content-save</v-icon></template>
    <template v-slot:head>
      <div>
        <parcel-intro-hint :parcelManaged="parcelManaged" v-show="$route.query.parcelId">
          <template v-slot:footer v-if="parcelData">
            <div class="mx-3 mt-n4 pb-4">
              Update any information that is out-of-date before saving.
            </div>
          </template>
        </parcel-intro-hint>
        <peeps-alert-animation
          :value="duplicate"
          :message="duplicateParcelMessage()"
          :color="exists.owner.self ? 'error' : 'warning'"
        >
        </peeps-alert-animation>
      </div>
    </template>
  </base-parcel-form>
</template>

<script>
import parcel from '@/mixins/parcel';
import audio from '@/mixins/audio';
import BaseParcelForm from '@/components/Parcel/BaseForm';
import _ from 'lodash';
import contact from '../../mixins/contact';
import config from '../../config';
const api = config.api();
export default {
  mixins: [parcel, audio, contact],
  components: {
    BaseParcelForm,
    ParcelIntroHint: () => import('./ParcelIntroHint.vue'),
    PeepsAlertAnimation: () => import('@/components/Core/Alerts/PeepsAlertAnimation.vue')
  },
  data() {
    return {
      parcelData: null,
      COORDINATE_DECIMAL_PLACES: 6,
      duplicate: false,
      exists: { owner: {} }
    };
  },
  computed: {
    parcelManaged() {
      return this.$store.getters.user.profile.parcelManaged;
    },
    disableCreateParcel: {
      get() {
        return this.$store.getters.disableCreateParcel;
      },
      set(val) {
        this.$store.commit('setDisableCreateParcel', val);
      }
    }
  },
  async mounted() {
    this.checkParcel();
  },
  methods: {
    duplicateParcelMessage() {
      let message = `This parcel already exists on `;

      if (this.exists.owner.self) {
        message += ' your';
      } else if (this.exists.owner.other) {
        message += ' someone else';
      } else {
        message = '';
      }
      if (message) {
        message += ` account`;
      }
      return message;
    },
    checkParcelExists: _.debounce(async function (parcel) {
      this.disableCreateParcel = false;
      parcel = this.addLeadingZero(parcel);

      try {
        const res = await this.parcelExists(parcel);
        if (res) {
          this.exists = res;
        }
      } catch (error) {
        console.error(error);
        return;
      }

      if (!this.exists) {
        return;
      }
      if (!this.exists.exists) {
        return;
      }

      if (this.exists.owner.self) {
        this.disableCreateParcel = true;
      }

      this.duplicate = true;
      setTimeout(() => {
        this.duplicate = false;
      }, 10000);

      if (this.exists.owner.other) {
        this.sendMessage({
          reply: false,
          type: 'other',
          message: `
          Duplicate Parcel

          Parcel ID: ${parcel}
          `
        });
      }
    }, 5000),
    async createParcel(parcel) {
      this.loadingAddParcel = true;
      let data = { ...parcel };
      data.number = this.addLeadingZero(data.number);

      await this.addParcel(data);
      this.$emit('created');
      this.playFrog();
      this.updateMetrics({
        parcelId: this.$store.getters.latestParcel.id,
        feature: 'parcel',
        action: 'create'
      });
      this.loadingAddParcel = false;
    },
    async checkParcel() {
      let parcelId = this.$route.query.parcelId;
      if (!parcelId) {
        return;
      }
      parcelId = this.fromMapParcel(parcelId);
      let parcelData;
      try {
        const url = `${api.truroAPI}/GetComputedDataApi?parcelId=${parcelId}`;
        parcelData = await this.$axios.get(url);
      } catch (err) {
        console.log(err);
        return;
      }

      parcelData = parcelData.data;
      if (!parcelData) {
        return;
      }
      if (!parcelData.meta || typeof parcelData.meta !== 'object') {
        return;
      }
      if (!parcelData.meta.status) {
        return;
      }
      parcelData = parcelData.data;
      if (!parcelData) {
        return;
      }

      let data = {};

      if (parcelData.acres) {
        data.acres = Number(parcelData.acres).toFixed(3);
      }

      data.bathroom = parcelData.bathrooms;
      data.bedroom = parcelData.bedrooms;
      data.key = parcelData.key;
      data.house_area = parcelData.netSf;
      data.state_class = parcelData.stateClass;
      if (parcelData.street) {
        data.street_name = parcelData.street.street_name;
        data.street_number = parcelData.street.street_number;
      }
      data.number = this.toMapParcel(parcelId);
      if (parcelData && typeof parcelData.center === 'object' && parcelData.center.length) {
        data.center = parcelData.center;
      }

      Object.keys(data).forEach(k => {
        data[k] = data[k] || '';
      });

      this.parcelData = data;
    }
  }
};
</script>

<style></style>
