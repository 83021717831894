import { computeDistance, searchParcel } from '../utils/parcel';

export default {
  data() {
    return {
      searchTimeout: null,
      searchCount: 0
    };
  },
  computed: {
    searchDialog: {
      get() {
        return this.$store.state.parcel.searchDialog;
      },
      set(value) {
        this.$store.commit('setSearchDialog', value);
      }
    },
    searchQuery: {
      get() {
        return this.$store.state.parcel.searchQuery;
      },
      set(value) {
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => {
          this.$store.commit('setSearchQuery', value);
        }, 500);
      }
    },
    searchedParcels() {
      let parcels = searchParcel(this.$store.state.parcel.parcels, this.searchQuery || '', true);
      if (this.$store.state.gps) {
        try {
          parcels = computeDistance(parcels, this.$store.state.gps, true);
        } catch {}
      }
      this.$emit('update:count', parcels.length);
      this.searchCount = parcels.length;
      return parcels;
    }
  }
};
